<template>
  <div class="comment-container-flex">
    <div class="comment-avatar">
      <el-avatar> {{ $h.getInitials(item) }} </el-avatar>
      <span class="unread-mark-avatar" v-if="showunread && !item.IsRead">
        <i class="fas fa-exclamation-circle"></i>
      </span>
    </div>
    <div class="comment-text-cont">
      <div class="comment-text-text">
        <strong>{{ $h.getFullName(item) }}</strong>
        <p class="field-val">{{ item.Remark }}</p>
        <p v-if="item.AdditionalRemark">{{ renderRemark(item.AdditionalRemark) }}</p>
        <div v-if="item.Attachment">
          <strong>Attachment:&nbsp;</strong>
          <a target="_blank" :href="$axios.formulateUrl('/media/view/' + item.Attachment)"
            ><i class="c-pointer fas fa-eye"></i
          ></a>
        </div>
      </div>

      <div class="d-flex" style="align-items: center; margin-top: 5px">
        <a
          v-if="AbleToRemove && editable && !item.IsStatus"
          href="javascript:;"
          class="comment-action"
          @click="remove(item.CommentKey)"
          >Remove</a
        >
        <a
          v-if="!item.ParentKey && editable"
          href="javascript:;"
          class="comment-action"
          @click="replyShow"
          >Reply</a
        >
        <span class="comment-time">{{ $h.formatDateTime(item.LastCreated) }}</span>
      </div>

      <!-- start for reply -->

      <div v-if="CommentList.length" style="margin-top: 15px">
        <template v-for="it in CommentList" :key="it.CommentKey">
          <GeneralCommentView
            :identifierkey="identifierkey"
            :type="type"
            :submitting="submitting"
            :remove="() => removeNow(it)"
            :reply="reply"
            :editable="editable"
            :showunread="showunread"
            :item="it"
          />
        </template>
      </div>

      <div
        v-if="showReply"
        class="comment-container-flex"
        style="margin-bottom: 0px; margin-top: 15px"
      >
        <div class="comment-avatar">
          <el-avatar> {{ $h.getInitials(User) }} </el-avatar>
        </div>
        <div class="comment-text-cont" style="padding: 0px; background: none">
          <el-input
            placeholder="Write a reply"
            :disabled="submitting"
            :autosize="{ minRows: 0, maxRows: 4 }"
            v-model="form.Comment"
            type="textarea"
          />

          <el-button @click="replySubmit" class="mt-3" type="primary" size="mini">
            {{ $t.translate("BTN_SUBMIT") }}
          </el-button>
        </div>
      </div>

      <!-- end for reply -->
    </div>
  </div>
</template>

<script>
import { configs, extendedConfigs } from "@/utils/configs";
import { reactive, onMounted, computed, ref } from "vue";
import { get, formulateUrl, post, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";
import GeneralCommentView from "@/components/GeneralCommentView";

export default {
  components: {
    GeneralCommentView,
  },
  props: {
    type: String,
    identifierkey: String,

    submitting: Boolean,
    showunread: Boolean,
    remove: Function,
    reply: Function,
    editable: Boolean,
    item: Object,
  },
  setup(props) {
    let { KYCRejectChoices } = extendedConfigs;
    let User = store.getters["user/getData"]?.UserData;
    let showReply = ref(false);
    let form = reactive({ Comment: "" });
    let CommentList = ref([]);

    onMounted(async () => {
      await getCommentList();
    });

    const getCommentList = async () => {
      let res = await get("/comment/list", {
        IdentifierKey: props.identifierkey,
        Type: props.type,
        size: 9999,
        page: 1,
        ReplyKey: props.item.CommentKey,
      });

      CommentList.value = res?.data || [];
    };

    const replyShow = () => {
      showReply.value = true;
    };

    let AbleToRemove = computed(() => {
      return User.UserKey == props.item.UserKey ? true : false;
    });

    const removeNow = async (item) => {
      await props.remove(item.CommentKey);
      await getCommentList();
    };

    const replySubmit = async () => {
      if (!form.Comment) return;
      await props.reply(form.Comment, props.item.CommentKey);
      form.Comment = ""; //reset
      await getCommentList();
    };

    const renderRemark = (val) => {
      let key = val.split(":");
      let found = _.find(KYCRejectChoices, (r) => {
        return r.value == key?.[0];
      });
      if (found) {
        let str = `${found.label}`;
        if (key[0] == "OTHERS") {
          key.shift();
          if (key.length) {
            str += ": " + key.join(" ").trim();
          }
        }
        return str;
      } else {
        return val;
      }
    };

    return {
      renderRemark,
      AbleToRemove,
      removeNow,
      replySubmit,
      CommentList,
      form,
      replyShow,
      User,
      showReply,
    };
  },
};
</script>
