<template>
  <div class="form-cont" v-loading="gettingList">
    <div class="sec-div fields-list border p-around mb-3 td-mx">
      <div class="row d-none d-md-flex border-bottom border-gray pb-2">
        <b class="col-md-1">#</b>
        <b class="col-md-7">{{ $t.translate("FRM_FILE_NAME") }}</b>
        <b class="col-md-3">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}</b>
        <b class="col-md-1 pl-md-0 pr-md-0">{{ $t.translate("TH_ACTION") }}</b>
      </div>

      <div v-if="!AttachmentList.length" class="row mt-2 align-items-center">
        <div class="col-md-12 text-center">
          {{ $t.translate("LBL_NO_ATTACHMENT_FOUND") }}
        </div>
      </div>

      <div
        v-for="(doc, docInd) in AttachmentList"
        :key="'form-doc-' + docInd"
        class="row mt-2 border-bottom border-gray align-items-center"
      >
        <div class="col-md-1">
          <p class="mb-2"><span class="d-md-none">#</span>{{ docInd + 1 }}</p>
        </div>
        <div class="col-md-7">
          <b class="d-md-none mr-2">{{ $t.translate("FRM_FILE_NAME") }}:</b>
          <p class="mb-2 field-val">{{ doc.FileName }}</p>
        </div>
        <div class="col-md-3">
          <b class="d-md-none mr-2">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}:</b>
          <p class="mb-2 field-val">{{ $h.formatDateTime(doc.UploadedAt) }}</p>
        </div>
        <div class="col-md-1 d-flex align-items-center pl-md-0 pr-md-0 mt-1 mt-md-0">
          <a
            class="el-button el-button--text"
            target="_blank"
            :href="$axios.formulateUrl('/export/download?Token=' + doc.DownloadToken)"
          >
            <i class="c-pointer fas fa-eye"></i>
          </a>

          <a
            class="el-button el-button--text"
            v-if="isAbleToAdd && !doc?.IsReadOnly"
            @click="removeNow(doc.AttachmentKey)"
            href="javascript:;"
          >
            <i class="c-pointer fas fa-trash"></i>
          </a>
        </div>
      </div>
    </div>

    <div v-if="isAbleToAdd" class="mt-3" style="margin-left: 7px">
      <small class="pre-line d-block">{{ $t.translate("LBL_UPLOAD_FILE_LIMIT") }}</small>
      <small class="pre-line d-block mb-3">{{
        $t.swapValues(
          {
            SPECIAL_CHARS: $h.getSpecialChars(),
          },
          $t.translate("LBL_UPLOAD_FILE_NAME_LIMIT")
        )
      }}</small>

      <el-upload
        :show-file-list="false"
        :action="$axios.formulateUrl('/media/upload/tmp?general=1')"
        :headers="$axios.getHeaders()"
        :on-error="$axios.uploadError"
        :on-progress="$axios.uploadProgress"
        :on-success="(res, file) => sucUploadFile(res, file, $axios.doneUpload)"
        :accept="$h.getGeneralFileTypes()"
      >
        <el-button type="primary" size="mini">
          {{ $t.translate("BTN_UPLOAD") }}
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed, ref } from "vue";
import { get, formulateUrl, post, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  components: {},
  props: {
    addFile: {
      type: Object,
      default: null,
    },
    add: Boolean,
    type: String,
    identifierkey: String,
  },
  setup(props) {
    let FormData = reactive({});
    let AttachmentListRaw = ref([]);
    let Submitting = ref(false);
    let gettingList = ref(false);

    let User = store.getters["user/getData"]?.UserData;

    let isAbleToAdd = computed(() => {
      return props?.add;
    });

    const AttachmentList = computed(() => {
      let arr = [...AttachmentListRaw.value];

      if (props?.addFile) {
        return [
          {
            FileName: props.addFile.Attachment,
            DownloadToken: props.addFile.DownloadToken,
            UploadedAt: props.addFile.LastCreated,
            IsReadOnly: true,
          },
          ...arr,
        ];
      }
      return arr;
    });

    const getAttachmentList = async () => {
      gettingList.value = true;
      let res = await get("/attachment/list", {
        IdentifierKey: props.identifierkey,
        Type: props.type,
        size: 9999,
        page: 1,
      });
      gettingList.value = false;
      AttachmentListRaw.value = res?.data || [];
    };

    onMounted(async () => {
      await getAttachmentList();
    });

    const submitAttachment = async () => {
      //save here
    };

    const removeNow = async (AttachmentKey) => {
      try {
        await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/attachment/remove", {
          AttachmentKey,
        });
        if (!isValidResponse(res)) return;
        //add alert
        helpers.showMessage({
          Code: "MSG_SUCCESS",
          Message: "Attachment removed successfully",
        });
        await getAttachmentList();
      } catch (err) {
        //error catch
      }
    };

    const sucUploadFile = async (response, file, cb) => {
      let res = await post("/attachment/save", {
        IdentifierKey: props.identifierkey,
        Type: props.type,
        FileName: file.name,
        File: response?.data?.File,
      });
      if (isValidResponse(res)) {
        await getAttachmentList();
      }
      cb();
    };

    return {
      sucUploadFile,
      FormData,
      gettingList,
      submitAttachment,
      Submitting,
      isAbleToAdd,
      getAttachmentList,
      AttachmentList,
      removeNow,
      User,
    };
  },
};
</script>
