<template>
  <div>
    <div style="padding: 10px">
      <el-alert
        v-if="!CommentList.length && !gettingList"
        :title="$t.translate('LBL_NO_COMMENT_FOUND')"
        type="warning"
        show-icon
        style="margin-bottom: 10px"
        :closable="false"
      ></el-alert>

      <div v-loading="gettingList">
        <template v-for="item in CommentList" :key="item.CommentKey">
          <GeneralCommentView
            :identifierkey="identifierkey"
            :type="type"
            :submitting="Submitting"
            :remove="removeNow"
            :reply="replyNow"
            :editable="isAbleToAdd"
            :showunread="isAbleToMark"
            :item="item"
          />
        </template>
      </div>

      <!-- add comments here -->
      <div class="comment-container-flex" style="margin-bottom: 0px" v-if="isAbleToAdd">
        <div class="comment-avatar">
          <el-avatar> {{ $h.getInitials(User) }} </el-avatar>
        </div>
        <div class="comment-text-cont" style="padding: 0px; background: none">
          <el-input
            :placeholder="$t.translate('FRM_WRITE_A_COMMENT')"
            :disabled="Submitting"
            v-model="FormData.Comment"
            :autosize="{ minRows: 1, maxRows: 4 }"
            resize="none"
            type="textarea"
          />
          <el-button @click="submitComment" class="mt-3" type="primary" size="mini">
            {{ $t.translate("BTN_SUBMIT") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed, ref } from "vue";
import { get, formulateUrl, post, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import GeneralCommentView from "@/components/GeneralCommentView";
import { store } from "@/store";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  components: {
    GeneralCommentView,
  },
  props: {
    mark: Boolean,
    add: Boolean,
    type: String,
    identifierkey: String,
  },
  setup(props) {
    let FormData = reactive({
      KYCFormKey: "",
      Comment: "",
      StatusCode: "",
    });

    let CommentList = ref([]);
    let Submitting = ref(false);
    let gettingList = ref(false);

    let User = store.getters["user/getData"]?.UserData;

    let isAbleToAdd = computed(() => {
      return props?.add;
    });

    let isAbleToMark = computed(() => {
      return props?.mark;
    });

    const getCommentList = async () => {
      gettingList.value = true;
      let res = await get("/comment/list", {
        IdentifierKey: props.identifierkey,
        Type: props.type,
        size: 9999,
        page: 1,
      });
      gettingList.value = false;
      CommentList.value = res?.data || [];
    };

    onMounted(async () => {
      await getCommentList();
    });

    const submitComment = async () => {
      if (!FormData.Comment) return;
      Submitting.value = true;
      let res = await post("/comment/save", {
        Remark: FormData.Comment,
        IdentifierKey: props.identifierkey,
        Type: props.type,
      });
      Submitting.value = false;
      if (isValidResponse(res)) {
        FormData.Comment = ""; // reset
        helpers.showMessage({
          Code: "MSG_SUCCESS",
          Message: "Comment successfully submitted",
        });
        //get list
        await getCommentList();
      }
    };

    const replyNow = async (Remark, ReplyKey) => {
      Submitting.value = true;
      let res = await post("/comment/save", {
        Remark,
        ReplyKey,
        IdentifierKey: props.identifierkey,
        Type: props.type,
      });
      Submitting.value = false;
      await getCommentList();
      return true;
    };

    const removeNow = async (CommentKey) => {
      try {
        await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/comment/remove", {
          CommentKey,
        });
        if (!isValidResponse(res)) return;
        //add alert
        helpers.showMessage({
          Code: "MSG_SUCCESS",
          Message: "Comment removed successfully",
        });
        await getCommentList();
      } catch (err) {
        //error catch
      }
    };

    return {
      FormData,
      gettingList,
      submitComment,
      Submitting,
      isAbleToAdd,
      isAbleToMark,
      getCommentList,
      CommentList,
      replyNow,
      removeNow,
      User,
    };
  },
};
</script>
<style></style>
