<template>
  <div class="pt-7 pb-7">
    <div class="container mt-2">
      <p class="h3 mb-4 text-capitalize text-dark px-3 text-center">
        {{ $h.formatName(Subscription, "ServiceName") }}
        <span v-if="$h.formatName(Subscription, 'ServiceNameExtra', true)">
          - {{ $h.formatName(Subscription, "ServiceNameExtra") }}</span
        >
      </p>

      <div class="row justify-content-end mx-0 mb-3">
        <el-dropdown
          v-if="loaded && $f.statusOptions(Subscription).length"
          trigger="click"
          :hide-on-click="true"
          @command="(comd) => $f.status(Subscription, comd, false, $refs, true)"
        >
          <el-button type="primary" size="mini">
            <i class="el-icon-arrow-down"></i> {{ $t.translate("BTN_OPTIONS") }}
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <template
                v-for="item in $f.statusOptions(Subscription)"
                :key="item.command"
              >
                <el-dropdown-item :command="item.command">{{
                  $t.translate(item.label)
                }}</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div v-if="IsCanPay" class="row mb-3">
        <div class="col-md-12">
          <div class="payment-alert">
            {{ $t.translate("LBL_APPLICATION_PAYMENT_WARNING") }}

            <el-button @click="payNow($refs)" type="primary" size="mini">
              {{ $t.translate("BTN_PAY_NOW") }}
            </el-button>
          </div>
        </div>
      </div>

      <div
        v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
        class="shadow bg-white p-around table-responsive mb-4"
      >
        <table class="form-table append-table">
          <tr>
            <td class="form-tb-head">{{ $t.translate("LBL_APPLICATION_PROGRESS") }}</td>
          </tr>

          <tr
            v-for="(item, index) in Subscription.ProgressIndicator"
            :key="index"
            class="form-record"
          >
            <td style="vertical-align: middle">
              <div class="d-flex justify-content-between">
                <div>{{ $t.translate(item.label) }}</div>
                <i class="large-fs" :class="$h.arrToObject(item.icon.split(' '))"></i>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="shadow bg-white p-around table-responsive mb-4">
        <table class="form-table append-table">
          <tr>
            <td class="form-tb-head">{{ $t.translate("TTL_BASIC_INFORMATION") }}</td>
          </tr>
          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_APPLICATION_NO") }}:</td>
            <td class="text-md-right">
              <b>{{ Subscription.ApplicationNo }}</b>
            </td>
          </tr>

          <tr class="form-record" v-if="Subscription.CertificateNo">
            <td class="sm-no-pb">{{ $h.formatName(Subscription, "TypeNumberName") }}:</td>
            <td class="text-md-right">
              <b>{{ Subscription.CertificateNo }}</b>
            </td>
          </tr>

          <tr class="form-record" v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
            <td class="sm-no-pb">{{ $t.translate("LBL_CREATED_BY") }}:</td>
            <td class="text-md-right">
              <b>{{ $h.getFullName(Subscription?.User) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_PAYMENT_STATUS") }}:</td>
            <td class="text-md-right">
              <el-tag
                :type="Subscription?.IsPaid ? 'success' : 'warning'"
                effect="dark"
                disable-transitions
                class="status-tag"
              >
                {{ $t.translate(Subscription?.PaymentLabel) }}</el-tag
              >
            </td>
          </tr>

          <template v-if="$h.hasRoles(['BUSINESS']) && Subscription?.PaymentProof">
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("LBL_PAYMENT_PROOF") }}:</td>
              <td class="text-md-right">
                <a
                  class="el-button el-button--text pb-0"
                  :href="
                    $axios.formulateUrl(
                      '/export/download?Token=' +
                        Subscription?.PaymentProof?.DownloadToken
                    )
                  "
                >
                  <i class="c-pointer fas fa-eye"></i>
                </a>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}:</td>
              <td class="text-md-right">
                <b>{{ $h.formatDateTime(Subscription.PaymentProof.LastCreated) }}</b>
              </td>
            </tr>
          </template>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_PAYMENT_DATE_BY_CLIENT") }}:</td>
            <td class="text-md-right">
              <b>{{ $h.formatDate(Subscription?.SettlementDateTime) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_PAYMENT_DATE_FINANCE") }}:</td>
            <td class="text-md-right">
              <b>{{ $h.formatDate(Subscription?.PaymentDateTime) }}</b>
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("TTL_INVOICE") }}:</td>
            <td class="text-md-right">
              <el-button
                size="mini"
                link
                class=""
                @click="
                  $refs.export.exportContent({
                    Mode: 'GENERATE_INVOICE',
                    Filter: {
                      ApplicationKey: Subscription.ApplicationKey,
                    },
                    PDFOnly: true,
                  })
                "
              >
                {{ $t.translate("BTN_VIEW_INVOICE") }}
              </el-button>
            </td>
          </tr>

          <tr>
            <td class="form-tb-head" style="padding-top: 20px">
              {{ $t.translate("LBL_APPLICATION_STATUS") }}
            </td>
          </tr>

          <tr class="form-record">
            <td class="sm-no-pb">{{ $t.translate("LBL_STATUS") }}:</td>
            <td class="text-md-right">
              <el-tag
                :type="applicationStatusStyle[Subscription?.ApplicationStatusCode]"
                effect="dark"
                disable-transitions
                class="status-tag"
                >{{ $t.translate(Subscription?.ApplicationStatusName) }}</el-tag
              >
            </td>
          </tr>

          <template v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_INTERNAL_CTRL_NO") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription?.InternalControlNo || "---" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_TRANX_NO") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription?.TransactionNo || "---" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_APPLICATION_STATUS_UPDATE_BY") }}:
              </td>
              <td class="text-md-right">
                <b>{{ $h.getFullName(Subscription?.LatestStatus) }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_APPLICATION_STATUS_UPDATE_DATE") }}:
              </td>
              <td class="text-md-right">
                <b>{{ $h.formatDateTime(Subscription?.LatestStatus?.LastCreated) }}</b>
              </td>
            </tr>
          </template>
        </table>

        <div class="td-px timeline-cont" v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
          <el-collapse>
            <el-collapse-item :title="$t.translate('FRM_STATUS_UPDATE_TIMELINE')">
              <el-timeline class="form-record pt-3 mt-2">
                <el-timeline-item
                  v-for="(submission, index) in TimelineForClient"
                  :key="index"
                  :timestamp="$h.formatDateTime(submission.LastCreated)"
                >
                  <span v-if="!submission.IsRemark">
                    {{
                      $t.swapValues(
                        {
                          User: $h.getFullName(submission.User, null, null, true),
                          ProcessUser: $h.getFullName(submission?.ProcessUser),
                          From: submission.From,
                          To: submission.To,
                        },
                        $t.translate(submission.RenderStatus)
                      )
                    }}
                  </span>
                  <span v-else>{{
                    $t.swapValues(
                      {
                        User: $h.getFullName(submission.User, null, null, true),
                        ProcessUser: $h.getFullName(submission?.ProcessUser),
                        From: submission.From,
                        To: submission.To,
                      },
                      $t.translate("LBL_REMARK_ADDED_BY_USER")
                    )
                  }}</span>

                  <div
                    v-if="submission.Remark"
                    style="white-space: pre-line"
                    class="field-val pr-2"
                  >
                    {{ submission.Remark }}
                  </div>
                </el-timeline-item>
              </el-timeline>

              <div
                class="comment-container-flex"
                style="margin-bottom: 0px; padding-left: 30px"
              >
                <div class="comment-avatar">
                  <el-avatar> {{ $h.getInitials(User) }} </el-avatar>
                </div>
                <div class="comment-text-cont" style="padding: 0px; background: none">
                  <el-input
                    :placeholder="$t.translate('FRM_WRITE_A_COMMENT')"
                    :disabled="formData.Submitting"
                    v-model="formData.Comment"
                    :autosize="{ minRows: 1, maxRows: 4 }"
                    resize="none"
                    type="textarea"
                  />
                  <el-button
                    @click="submitComment"
                    class="mt-3 f-white"
                    type="primary"
                    size="mini"
                  >
                    {{ $t.translate("BTN_SUBMIT") }}
                  </el-button>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div
        v-if="Subscription?.ApplicationKey"
        class="shadow bg-white p-around table-responsive mt-4"
      >
        <!-- if admin, hide this section -->
        <table class="form-table append-table">
          <tr>
            <td class="form-tb-head">{{ $t.translate("LBL_ASSESSOR_REMARK") }}</td>
          </tr>
        </table>
        <GeneralComments
          :mark="$h.hasRoles(['ASSESSOR', 'CLIENT', 'SUB_CLIENT', 'ADMIN', 'SUB_ADMIN'])"
          :add="$h.hasRoles(['ASSESSOR', 'CLIENT', 'SUB_CLIENT', 'ADMIN', 'SUB_ADMIN'])"
          :identifierkey="Subscription?.ApplicationKey"
          type="APPLICATION"
        />
      </div>

      <div
        v-if="Subscription?.ApplicationKey && !$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
        class="shadow bg-white p-around table-responsive mt-4"
      >
        <!-- if admin, hide this section -->
        <table class="form-table append-table">
          <tr>
            <td class="form-tb-head">
              {{ $t.translate("LBL_DISCLOSURE_PLATFORM_REMARK") }}
            </td>
          </tr>
        </table>

        <div class="form-cont">
          <p class="form-control-label">
            {{ $t.translate("FRM_REMARK") }} {{ $t.translate("LANG_EN") }}
          </p>

          <el-input
            :placeholder="$t.translate('FRM_REMARK')"
            v-model="Subscription.Remark"
            :autosize="{ minRows: 3, maxRows: 6 }"
            resize="none"
            type="textarea"
          />

          <p class="form-control-label mt-3">
            {{ $t.translate("FRM_REMARK") }} {{ $t.translate("LANG_CHT") }}
          </p>

          <el-input
            :placeholder="$t.translate('FRM_REMARK')"
            v-model="Subscription.RemarkCHT"
            :autosize="{ minRows: 3, maxRows: 6 }"
            resize="none"
            type="textarea"
          />

          <p class="form-control-label mt-3">
            {{ $t.translate("FRM_REMARK") }} {{ $t.translate("LANG_CHS") }}
          </p>

          <el-input
            :placeholder="$t.translate('FRM_REMARK')"
            v-model="Subscription.RemarkCHS"
            :autosize="{ minRows: 3, maxRows: 6 }"
            resize="none"
            type="textarea"
          />

          <el-button
            @click="submitRemark"
            class="mt-3 f-white"
            type="primary"
            size="mini"
          >
            {{ $t.translate("BTN_SUBMIT") }}
          </el-button>
        </div>

        <!-- <GeneralComments
          :mark="$h.hasRoles(['ASSESSOR', 'ADMIN', 'SUB_ADMIN'])"
          :add="$h.hasRoles(['ASSESSOR', 'ADMIN', 'SUB_ADMIN'])"
          :identifierkey="Subscription?.ApplicationKey"
          type="DISCLOSURE"
        /> -->
      </div>

      <div
        v-if="Subscription?.ApplicationKey"
        class="shadow bg-white p-around table-responsive mt-4"
      >
        <table class="form-table append-table mb-1">
          <tr>
            <td class="form-tb-head">{{ $t.translate("LBL_FILE_TO_APPLICANT") }}</td>
          </tr>
        </table>

        <GeneralAttachments
          :add="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
          :identifierkey="Subscription?.ApplicationKey"
          type="CLIENT"
        />
      </div>

      <div
        v-if="Subscription?.ApplicationKey && $h.notAnyRoles(['CLIENT', 'SUB_CLIENT'])"
        class="shadow bg-white p-around table-responsive mt-4"
      >
        <table class="form-table append-table mb-1">
          <tr>
            <td class="form-tb-head">{{ $t.translate("LBL_FILE_FOR_INTERNAL_USE") }}</td>
          </tr>
        </table>

        <GeneralAttachments
          :addFile="Subscription.SupportingRelevantDoc"
          :add="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])"
          :identifierkey="Subscription?.ApplicationKey"
          type="INTERNAL"
        />
      </div>

      <div id="headerTopComp" class="mt-4" />
      <div id="myHeader" class="sticky-header">
        <div class="d-flex scroll-header">
          <div
            class="scroll-item"
            :class="{ 'active-secbar': formData.activeSection == 'secProposal' }"
            @click="scrollTo('secProposal')"
          >
            <p class="mb-0">{{ $t.translate("TTL_PROPOSAL") }}</p>
          </div>
          <div
            class="scroll-item"
            :class="{ 'active-secbar': formData.activeSection == 'secApplicant' }"
            @click="scrollTo('secApplicant')"
          >
            <p class="mb-0">{{ $t.translate("TTL_APPLICANT_INFO") }}</p>
          </div>
          <div
            class="scroll-item"
            :class="{ 'active-secbar': formData.activeSection == 'secContact' }"
            @click="scrollTo('secContact')"
          >
            <p class="mb-0">{{ $t.translate("TTL_CONTACT_INFO") }}</p>
          </div>
          <div
            class="scroll-item"
            :class="{ 'active-secbar': formData.activeSection == 'secSupdocs' }"
            @click="scrollTo('secSupdocs')"
          >
            <p class="mb-0">{{ $t.translate("TTL_DOCUMENTS") }}</p>
          </div>
          <div
            v-if="Subscription.MachformEntryKey"
            class="scroll-item"
            :class="{ 'active-secbar': formData.activeSection == 'secChecklist' }"
            @click="scrollTo('secChecklist')"
          >
            <p class="mb-0">{{ $t.translate("TTL_ASSESSMENT_CHECKLIST") }}</p>
          </div>
        </div>
      </div>
      <div id="myHeaderTemp"></div>

      <div class="shadow bg-white p-around table-responsive">
        <div class="form-cont px-0">
          <div class="d-flex justify-content-between">
            <p ref="secProposal" class="sec-head td-px mb-2">
              {{ $t.translate("TTL_PROPOSAL") }}
            </p>
            <!-- <p class="td-px mb-2">Follow up</p> -->
          </div>
          <table class="form-table append-table mb-2">
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_PROPOSAL_QUOTA_NO") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription?.ProposalNo || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_PROPOSAL_QUOTA_BY_SYSTEM") }}:
              </td>
              <td class="text-md-right">
                <b
                  ><a
                    v-if="Subscription?.ProposalFile?.File"
                    class="c-pointer"
                    target="_blank"
                    :href="
                      $axios.formulateUrl(
                        '/export/download?Token=' +
                          Subscription?.ProposalFile?.DownloadToken
                      )
                    "
                    >{{ Subscription?.ProposalFile?.File }}</a
                  ><span v-else>{{ "N/A" }}</span></b
                >
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_APPLICATION_FEE") }}:</td>
              <td class="text-md-right">
                <b>{{
                  $h.formatPrice(
                    Subscription.ApplicationFee,
                    Subscription?.CurrencyLabel?.label
                  )
                }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_ASSESSMENT_FEE") }}:</td>
              <td class="text-md-right">
                <b>{{
                  $h.formatPrice(
                    Subscription.AssessmentFee,
                    Subscription?.CurrencyLabel?.label
                  )
                }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_TOTAL_REGISTRATION_FEE") }}:</td>
              <td class="text-md-right">
                <b>{{
                  $h.formatPrice(TotalRegisFee, Subscription?.CurrencyLabel?.label)
                }}</b>
              </td>
            </tr>
          </table>

          <p ref="secApplicant" class="sec-head td-px mb-2 mt-4">
            {{ $t.translate("TTL_APPLICANT_INFO") }}
          </p>
          <table class="form-table append-table mb-2">
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                {{ $t.translate("LANG_EN") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.Company || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                {{ $t.translate("LANG_CHT") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.CompanyCHT || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_COMPANY_NAME_SAME_BUSINESS_REG") }}
                {{ $t.translate("LANG_CHS") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.CompanyCHS || "N/A" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_EN") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.BranchName || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_CHT") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.BranchNameCHT || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_CHS") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.BranchNameCHS || "N/A" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_REGION") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription.RegionLabel || "N/A" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                {{ $t.translate("LANG_EN") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.Address || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                {{ $t.translate("LANG_CHT") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.AddressCHT || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
                {{ $t.translate("LANG_CHS") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.AddressCHS || "N/A" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_CORRESPONDING_ADDRESS_IF_DIFFERENT") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.CorrespondingAddress || "N/A" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_INDUSTRY") }}:</td>
              <td class="text-md-right">
                <b>{{ $h.formatName(Subscription, "IndustryName") || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_GENERAL_TEL_LINE") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription.MobileCode }} {{ Subscription.MobileNumber }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_FAX") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription.Fax || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_GENERAL_EMAIL") }}:</td>
              <td class="text-md-right">
                <b>{{ Subscription.Email || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_WEBSITE") }} ({{ $t.translate("LBL_IF_ANY") }}):
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.Website || "N/A" }}</b>
              </td>
            </tr>

            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_EN") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.Scope || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_CHT") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.ScopeCHT || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SCOPE") }} {{ $t.translate("LANG_CHS") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.ScopeCHS || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SCOPE_CATG") }}
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.ScopeCategory || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SITE_ADDR_IFANY") }} {{ $t.translate("LANG_EN") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.ScopeAddress || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SITE_ADDR_IFANY") }} {{ $t.translate("LANG_CHT") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.ScopeAddressCHT || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("FRM_SITE_ADDR_IFANY") }} {{ $t.translate("LANG_CHS") }}:
              </td>
              <td class="text-md-right">
                <b>{{ Subscription.ScopeAddressCHS || "N/A" }}</b>
              </td>
            </tr>
            <tr class="form-record">
              <td class="sm-no-pb">{{ $t.translate("FRM_LANG_PREFER_FOR_ASM") }}:</td>
              <td class="text-md-right">
                <b>{{
                  $h.mapLabel(Subscription.PreferredAssessmentLang, LangList) || "N/A"
                }}</b>
              </td>
            </tr>
          </table>

          <p ref="secContact" class="sec-head td-px mb-2 mt-4">
            {{ $t.translate("TTL_CONTACT_INFO") }}
          </p>
          <table class="form-table append-table mb-2">
            <template v-for="(person, index) in Subscription.contactInfo" :key="index">
              <tr class="form-record">
                <td class="sm-no-pb">
                  <b>{{ $t.translate(person.Label) }}</b>
                </td>
                <td class="text-md-right"></td>
              </tr>
              <tr class="form-record">
                <td class="sm-no-pb">{{ $t.translate("FRM_NAME") }}</td>
                <td class="text-md-right">
                  <b
                    >{{ $h.mapLabel(person.PersonTitle, PersonTitleOpts) }}
                    {{ person.Name || "N/A" }}</b
                  >
                </td>
              </tr>
              <tr class="form-record">
                <td class="sm-no-pb">{{ $t.translate("FRM_POSITION") }}</td>
                <td class="text-md-right">
                  <b>{{ person.Position || "N/A" }}</b>
                </td>
              </tr>
              <tr class="form-record">
                <td class="sm-no-pb">{{ $t.translate("FRM_MOBILE_NUMBER") }}</td>
                <td class="text-md-right">
                  <b>{{
                    person.MobileNumber
                      ? person.MobileCode + " " + person.MobileNumber
                      : "N/A"
                  }}</b>
                </td>
              </tr>

              <tr class="form-record">
                <td class="sm-no-pb">{{ $t.translate("FRM_EMAIL") }}</td>
                <td class="text-md-right">
                  <b>{{ person.Email || "N/A" }}</b>
                </td>
              </tr>
            </template>
          </table>

          <div class="d-flex justify-content-between align-items-center mb-2 mt-4 td-px">
            <p ref="secSupdocs" class="sec-head mb-0">
              {{ $t.translate("TTL_DOCUMENTS") }}
            </p>
          </div>

          <div class="sec-div fields-list border p-around mb-3 td-mx">
            <div class="row d-none d-md-flex border-bottom border-gray pb-2">
              <b class="col-md-1">#</b>
              <b class="col-md-4">{{ $t.translate("FRM_DOCUMENT_TYPE") }}</b>
              <b class="col-md-3">{{ $t.translate("FRM_FILE_NAME") }}</b>
              <b class="col-md-3">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}</b>
              <b class="col-md-1 pl-md-0 pr-md-0">{{ $t.translate("TH_ACTION") }}</b>
            </div>

            <div
              class="row mt-2 align-items-center"
              v-if="Subscription?.formDocuments?.length == 0"
            >
              <div class="col-md-12">N/A</div>
            </div>

            <div
              v-for="(doc, docInd) in Subscription.formDocuments"
              :key="'form-doc-' + docInd"
              class="row mt-2 border-bottom border-gray align-items-center"
            >
              <div class="col-md-1">
                <p class="mb-2"><span class="d-md-none">#</span>{{ docInd + 1 }}</p>
              </div>
              <div class="col-md-4">
                <b class="d-md-none mr-2">{{ $t.translate("FRM_DOCUMENT_TYPE") }}:</b>
                <p class="mb-2 field-val">
                  {{ $t.translate($h.mapLabel(doc.DocuType, DocuTypes)) }}
                </p>
              </div>
              <div class="col-md-3">
                <b class="d-md-none mr-2">{{ $t.translate("FRM_FILE_NAME") }}:</b>
                <p class="mb-2 field-val">{{ doc.FileName }}</p>
              </div>
              <div class="col-md-3">
                <b class="d-md-none mr-2">{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}:</b>
                <p class="mb-2 field-val">{{ $h.formatDateTime(doc.UploadedAt) }}</p>
              </div>
              <div
                class="col-md-1 d-flex align-items-center pl-md-0 pr-md-0 mt-1 mt-md-0"
              >
                <a
                  class="el-button el-button--text"
                  target="_blank"
                  :href="
                    $axios.formulateUrl('/export/download?Token=' + doc.DownloadToken)
                  "
                >
                  <i class="c-pointer fas fa-eye"></i>
                </a>
              </div>
            </div>
          </div>

          <table class="form-table append-table">
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_TICK_IF_DONT_RECEIVE_INFO") }}
              </td>
              <td class="text-md-right">
                {{
                  Subscription.notReceiveInfo
                    ? $t.translate("LBL_YES")
                    : $t.translate("LBL_NO")
                }}
              </td>
            </tr>
          </table>

          <template v-if="Subscription.MachformEntryKey">
            <div
              class="d-flex"
              style="justify-content: space-between; align-items: center"
            >
              <p ref="secChecklist" class="sec-head td-px mb-2 mt-4">
                {{ $t.translate("TTL_ASSESSMENT_CHECKLIST") }}
              </p>
              <div v-if="Subscription.IsHaveDisclosureDocument">
                <el-button
                  @click="
                    $refs.export.exportContent({
                      Mode: 'GENERATE_MACHFORM_ENTRY',
                      ApplicationKey: Subscription.ApplicationKey,
                      MachformEntryKey: Subscription.MachformEntryKey,
                      MachformId: Subscription.MachformId,
                      PDFOnly: true,
                    })
                  "
                  type="default"
                >
                  {{ $t.translate("BTN_EXPORT") }}
                </el-button>
              </div>
            </div>

            <div class="td-px mb-4">
              <AssessmentChecklist :data="Subscription" />
            </div>
          </template>

          <table class="form-table append-table">
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_AGREE_SELF_DECLAR_ALT") }}
              </td>
              <td class="text-md-right">
                {{
                  Subscription.agreeDecla
                    ? $t.translate("LBL_YES")
                    : $t.translate("LBL_NO")
                }}
              </td>
            </tr>
          </table>

          <table class="form-table append-table">
            <tr class="form-record">
              <td class="sm-no-pb">
                {{ $t.translate("LBL_AGREE_APPLI_TERMS_CONDI_ALT") }}
              </td>
              <td class="text-md-right">
                {{
                  Subscription.agreeTerms
                    ? $t.translate("LBL_YES")
                    : $t.translate("LBL_NO")
                }}
              </td>
            </tr>
          </table>

          <table class="form-table append-table mt-4">
            <tr class="form-record">
              <td class="sm-no-pb">
                <b>{{ $t.translate("FRM_ACCEPTED_CONFIRMED_BY") }}:</b>

                <div class="mt-2">
                  <el-image
                    v-if="
                      Subscription?.Signature && Subscription?.SignatureType == 'SIGN'
                    "
                    class="sign-image"
                    :src="
                      $axios.formulateUrl(`/image/view/tmp/${Subscription.Signature}`)
                    "
                  ></el-image>

                  <a
                    v-if="
                      Subscription?.Signature && Subscription?.SignatureType == 'UPLOAD'
                    "
                    class="c-pointer"
                    target="_blank"
                    :href="
                      $axios.formulateUrl(
                        '/export/download?Token=' + Subscription?.Signature?.DownloadToken
                      )
                    "
                    style="word-wrap: break-word; display: block"
                    >{{ Subscription.Signature.File }}</a
                  >
                  <br />

                  <small
                    style="display: block"
                    v-if="Subscription.SignedByTitle && Subscription.SignedBy"
                    >{{
                      $t
                        .translate("LBL_SIGNED_BY_ON_BEHALF_OF_COMPANY")
                        .replace(
                          "{SIGNED}",
                          `${$h.mapLabel(Subscription.SignedByTitle, PersonTitleOpts)}. ${
                            Subscription.SignedBy
                          }`
                        )
                        .replace(
                          "{SIGNED_CH}",
                          `${Subscription.SignedBy}${$h.mapLabel(
                            Subscription.SignedByTitle,
                            PersonTitleOpts
                          )}`
                        )
                        .replace("{COMPANY}", $h.formatOrgBranch(Subscription))
                    }}</small
                  >

                  <small
                    >{{ $t.translate("FRM_SIGNED_DATE") }}:
                    {{ $h.formatDateTime(Subscription.SignDate) }}</small
                  >
                </div>
              </td>
              <td class="text-md-right"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <ApplicationModal ref="select" />
  <ExportModal :types="['PDF', 'EXCEL']" ref="export" />
</template>

<script>
import { useRoute } from "vue-router";
import _ from "lodash";
import moment from "moment";
import { onMounted, onUnmounted, reactive, ref, computed } from "vue";
import { helpers } from "@/utils/helpers";
import { flow } from "@/utils/flow";
import { extendedConfigs } from "@/utils/configs";
import { get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import ApplicationModal from "@/components/ApplicationModal";
import AssessmentChecklist from "@/components/AssessmentChecklist";
import ExportModal from "@/components/ExportModal";
import GeneralComments from "@/components/GeneralComments";
import GeneralAttachments from "@/components/GeneralAttachments";
import signedImageData from "@/assets/sample_signature";
import { store } from "@/store";

export default {
  components: {
    ApplicationModal,
    ExportModal,
    GeneralComments,
    GeneralAttachments,
    AssessmentChecklist,
  },
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    let { applicationStatusStyle } = extendedConfigs;
    let { LangList, PersonTitleOpts, DocuTypes, BoundaryProgram } = store.getters[
      "config/getData"
    ]?.envConf;

    let User = store.getters["user/getData"]?.UserData;

    let currentStep = ref(0);
    let formData = reactive({
      activeSection: "",
      Comment: "",
      Submitting: false,
      Attachments: [
        { Type: "ToApplicant", Label: "LBL_FILE_TO_APPLICANT", Files: [] },
        { Type: "InternalUse", Label: "LBL_FILE_FOR_INTERNAL_USE", Files: [] },
      ],
    });

    let Subscription = ref({});

    let IsCanPay = computed(() => {
      if (!Subscription.value) return false;

      let arr = flow.statusOptions(Subscription.value);
      return _.find(arr, (r) => {
        return r.command == "PAY_NOW";
      });
    });

    let ApplicationRemark = reactive({
      IsApproved: false,
      Comment: null,
      DisclosureComment: null, // Remarks to be inputted by Assessor to be displayed on disclosure platform.
    });

    let secProposal = ref(null);
    let secApplicant = ref(null);
    let secContact = ref(null);
    let secSupdocs = ref(null);
    let secChecklist = ref(null);
    let secRemark = ref(null);

    const TimelineForClient = computed(() => {
      return Subscription?.value?.StatusHistory || [];
    });

    onMounted(async () => {
      let info = await get("/application/view", { ApplicationKey: route.query.key });
      Subscription.value = info?.Application;

      if (!info?.Application?.IsAbleToEditForm) {
        return router.replaceRoute("/applications");
      }

      // if (info?.Application?.ApplicationStatusCode == "INPROGRESS") {
      //   return router.replaceRoute("/applications");
      // }

      window.addEventListener("scroll", handleScroll);
      loaded.value = true;
    });

    const TotalRegisFee = computed(() => {
      return Subscription.value?.ApplicationFee + Subscription.value.AssessmentFee;
    });

    const regisFieldChecked = (key) => {
      return Subscription.value.RegisBoundary == key;
    };

    const updateFollowUpFields = (val, field) => {
      var contains = _.includes(formData.FollowedFields, field);

      if (val == true && !contains) {
        formData.FollowedFields.push(field);
      }
      if (val == false && contains) {
        formData.FollowedFields = _.filter(formData.FollowedFields, (it) => it != field);
      }
    };

    const returnToPrevLevel = () => {
      // from client comment: Return means return to up one level
    };
    const rejectFunc = () => {
      // from client comment: Reject means the application is not successful.
    };
    const approveFunc = () => {};

    const canPay = () => {
      if (
        _.findIndex(flow.statusOptions(Subscription.value), ["command", "PAYMENT"]) != -1
      ) {
        return true;
      }
      return false;
    };

    const payNow = ($refs) => {
      flow.status(Subscription.value, "PAY_NOW", false, $refs);
      // router.changeRoute(`/invoice/${Subscription.value.ApplicationKey}`);
    };

    const backPre = () => {
      currentStep.value = currentStep.value - 1;
    };

    const toNext = () => {
      currentStep.value = currentStep.value + 1;
    };

    const scrollTo = (refName, offset) => {
      formData.activeSection = refName;

      var top = 0;
      if (refName == "secProposal") {
        top = secProposal.value.offsetTop;
      } else if (refName == "secApplicant") {
        top = secApplicant.value.offsetTop;
      } else if (refName == "secContact") {
        top = secContact.value.offsetTop;
      } else if (refName == "secSupdocs") {
        top = secSupdocs.value.offsetTop;
      } else if (refName == "secChecklist") {
        top = secChecklist.value.offsetTop;
      } else if (refName == "secRemark") {
        top = secRemark.value.offsetTop;
      }

      var pad = offset || 140;
      window.scrollTo(0, top - pad);
    };
    const handleScroll = () => {
      var header = document.getElementById("myHeader");
      var tempHeader = document.getElementById("myHeaderTemp");
      var headerTopComp = document.getElementById("headerTopComp");

      if (window.pageYOffset > headerTopComp.offsetTop - 59) {
        header.classList.add("sticky");
        tempHeader.classList.add("sticky-header");
      } else if (window.pageYOffset < headerTopComp.offsetTop - 59) {
        header.classList.remove("sticky");
        tempHeader.classList.remove("sticky-header");
      }

      if (window.pageYOffset < secProposal.value.offsetTop - 142) {
        formData.activeSection = null;
      } else if (
        window.pageYOffset >= secProposal.value.offsetTop - 142 &&
        window.pageYOffset < secApplicant.value.offsetTop - 142
      ) {
        formData.activeSection = "secProposal";
      } else if (
        window.pageYOffset >= secApplicant.value.offsetTop - 142 &&
        window.pageYOffset < secContact.value.offsetTop - 142
      ) {
        formData.activeSection = "secApplicant";
      } else if (
        window.pageYOffset >= secContact.value.offsetTop - 142 &&
        window.pageYOffset < secSupdocs.value.offsetTop - 195
      ) {
        formData.activeSection = "secContact";
      } else if (
        window.pageYOffset >= secSupdocs.value.offsetTop - 142 &&
        window.pageYOffset < secChecklist.value.offsetTop - 142
      ) {
        formData.activeSection = "secSupdocs";
      } else {
        formData.activeSection = "secChecklist";
      }
    };

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const submitComment = async () => {
      if (!formData.Comment || formData.Submitting) return;
      formData.Submitting = true;
      let res = await post("/application/comment", {
        Remark: formData.Comment,
        ApplicationKey: route.query.key,
      });
      formData.Submitting = false;
      if (isValidResponse(res)) {
        formData.Comment = null;
        let info = await get("/application/view", { ApplicationKey: route.query.key });
        Subscription.value.StatusHistory = info.Application.StatusHistory;
      }
    };

    const sucUploadFiles = async (response, file, fileList, type) => {
      _.each(formData.Attachments, function (item) {
        if (item.Type == type) {
          item.Files.push({
            Type: type, // 'ToApplicant', 'InternalUse'
            FileName: file.name,
            UploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            File: response?.data?.File,
            DownloadToken: response?.data?.DownloadToken,
          });
        }
      });
    };

    const submitRemark = async () => {
      let res = await post("/application/remark", {
        Remark: Subscription?.value?.Remark,
        RemarkCHS: Subscription?.value?.RemarkCHS,
        RemarkCHT: Subscription?.value?.RemarkCHT,
        ApplicationKey: route.query.key,
      });
      if (isValidResponse(res)) {
        helpers.showMessage({
          Code: "MSG_SUCCESS",
          Message: "MSG_REQUEST_SUCCESS",
        });
      }
    };

    return {
      submitRemark,
      LangList,
      IsCanPay,
      submitComment,
      User,
      BoundaryProgram,
      loaded,
      applicationStatusStyle,
      currentStep,
      formData,
      regisFieldChecked,
      backPre,
      toNext,
      returnToPrevLevel,
      rejectFunc,
      approveFunc,
      canPay,
      payNow,
      TotalRegisFee,
      PersonTitleOpts,
      DocuTypes,
      Subscription,
      ApplicationRemark,
      scrollTo,
      handleScroll,
      secProposal,
      secApplicant,
      secContact,
      secSupdocs,
      secChecklist,
      secRemark,
      TimelineForClient,
      updateFollowUpFields,
      sucUploadFiles,
    };
  },
};
</script>

<style>
.w-follow {
  min-width: 80px;
  text-align: right;
  padding-right: 2px;
}
.status-tag {
  height: unset;
  padding: 3px 10px;
  line-height: unset;
}
.timeline-cont .el-collapse,
.timeline-cont .el-collapse-item__wrap,
.timeline-cont .el-collapse-item__header {
  border-top: 0px;
  border-bottom: 0px;
}
.timeline-cont .el-collapse {
  --el-collapse-header-height: unset;
  padding: 8px 0px;
}
.timeline-cont .el-collapse-item__content {
  padding-bottom: 5px;
}
.timeline-cont .el-collapse * {
  font-size: 0.85rem;
  /* color: black; */
}
.fixed-b-row {
  position: fixed;
  bottom: 0px;
  display: flex;
  width: 100%;
  z-index: 99;
}
.sign-image {
  width: 200px;
  height: 100px;
  border: 1px solid #dfdddd;
  object-fit: contain;
}
.attach-upl-box .el-upload,
.attach-upl-box .el-upload .el-upload-dragger {
  height: 124px;
}
.attach-upl-box .el-upload-dragger .el-icon-upload {
  margin: 20px 0px 16px;
}

@media (min-width: 769px) and (max-width: 1180px) {
  .text-md-right {
    display: flex !important;
    justify-content: flex-end;
  }
  .text-md-right b {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .text-md-right {
    display: flex !important;
  }
  .w-follow {
    min-width: 30px;
    flex: 1;
    text-align: right;
  }
  .nav-bottom .sm-cus-btn {
    min-width: unset;
    padding: 0px 15px;
  }
  .attach-upl-box .el-upload,
  .attach-upl-box .el-upload .el-upload-dragger {
    width: 100%;
  }
}
</style>
